<template>
  <v-navigation-drawer v-bind="$attrs">
    <v-card flat>
      <v-card-title primary-title class="text-center py-3">
        NiO Admin
      </v-card-title>
    </v-card>
    <v-divider />
    <v-list>
      <v-list-item
        link
        prepend-icon="mdi-home"
        title="Painel"
        :to="{ name: 'home' }"
      ></v-list-item>
      <v-list-item
        link
        prepend-icon="mdi-account"
        :to="{ name: 'users' }"
        title="Usuários"
      >
      </v-list-item>
      <v-list-item
        link
        prepend-icon="mdi-cube"
        :to="{ name: 'masterRoom.games' }"
        title="Jogos"
      >
      </v-list-item>
      <v-list density="compact">
        <v-list-subheader>Relatórios</v-list-subheader>

        <v-list-item
          link
          prepend-icon="mdi-chat-question-outline"
          title="IA no chat de questões"
          :to="{ name: 'reports.questionsChatInteractions' }"
        ></v-list-item>
      </v-list>

      <v-list-item
        link
        prepend-icon="mdi-cog"
        title="Configurações"
      ></v-list-item>
      <v-list-item
        link
        prepend-icon="mdi-logout"
        title="Sair"
        @click="logout()"
      ></v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script setup>
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const router = useRouter();

async function logout() {
  store.dispatch("auth/logout").then(() => {
    router.push({ name: "login" });
  });
}
</script>
