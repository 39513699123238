<template lang="">
  <div class="px-3 py-3" style="">
    <header class="d-flex align-center justify-space-between mb-3">
      <h1>Jogos ({{ !paginator ? 0 : paginator.totalElements }})</h1>
      <div class="d-flex align-center justify-end">
        <OrderByGamesMenu
          v-model="menu"
          @onClose="menu = false"
          @onUpdateOrderBY="onUpdateOrderBY"
        />
        <v-btn
          class="mr-2"
          prepend-icon="mdi-filter"
          color="white"
          @click="() => (showUserFiltersModal = true)"
          >Filtrar</v-btn
        >
      </div>
    </header>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="games"
          :items-per-page="perPage"
          :return-object="true"
          @update:itemsPerPage="(p) => (perPage = p)"
          :page="page"
          items-per-page-text="Itens por página"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.gameName }}</td>
              <td>{{ item.ownerGameName }}</td>
              <td>{{ item.owenerGamePlan }}</td>
              <td>{{ item.pin }}</td>
              <td>{{ item.galaxies }}</td>
              <td>{{ item.constellations }}</td>
              <td>{{ item.planets }}</td>
              <td>{{ item.questions }}</td>
              <td>{{ item.players }}</td>
              <td>{{ item.dateCreate }}</td>
              <td>{{ item.dateUpdate }}</td>
            </tr>
          </template>

          <template v-slot:bottom v-if="paginator">
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="paginator.totalPages"
              ></v-pagination>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
  <GameFiltersModal
    v-model="showUserFiltersModal"
    @OnFilter="search"
    @onClose="showUserFiltersModal = false"
  />
</template>
<script>
import { onMounted, ref, watch } from "vue";
import masterRoomGameService from "../../../services/masterRoomGameService";
import OrderByGamesMenu from "./OrderByGamesMenu.vue";
import GameFiltersModal from "./GameFiltersModal.vue";
import { config } from "../../../config/AppConfig";

export default {
  name: "GameListView",
  components: {
    OrderByGamesMenu,
    GameFiltersModal,
  },
  setup() {
    const headers = ref([
      {
        title: "Nome",
        align: "start",
        key: "gameName",
        sortable: false,
      },
      {
        title: "Responsável",
        align: "start",
        key: "ownerGameName",
        sortable: false,
      },
      {
        title: "Plano",
        align: "center",
        key: "owenerGamePlan",
        sortable: false,
      },
      {
        title: "PIN",
        align: "center",
        key: "pin",
        sortable: false,
      },
      {
        title: "Galáxias",
        align: "center",
        key: "galaxies",
        sortable: false,
      },
      {
        title: "Constelações",
        align: "center",
        key: "constellations",
        sortable: false,
      },
      {
        title: "Planetas",
        align: "center",
        key: "planets",
        sortable: false,
      },
      {
        title: "Questões",
        align: "center",
        key: "questions",
        sortable: false,
      },
      {
        title: "Estudantes",
        align: "center",
        key: "players",
        sortable: false,
      },
      {
        title: "Data Criação",
        align: "center",
        key: "dateCreate",
        sortable: false,
      },
      {
        title: "Última atualização",
        align: "center",
        key: "dateUpdate",
        sortable: false,
      },
    ]);
    const games = ref([]);
    const page = ref(1);
    const perPage = ref(config.registersPerPage);
    const filters = ref(null);
    const orderBy = ref("name");
    const paginator = ref(null);
    const menu = ref(false);
    const showUserFiltersModal = ref(false);

    watch(page, () => {
      loadGames();
    });

    watch(orderBy, () => {
      loadGames();
    });

    onMounted(() => {
      loadGames();
    });

    async function loadGames() {
      console.log(orderBy);

      const pageNumber = page.value && page.value >= 1 ? page.value - 1 : 0;
      const resp = await masterRoomGameService.list(
        pageNumber,
        perPage.value,
        orderBy.value,
        filters.value
      );

      if (resp.data) {
        games.value = [...resp.data.content];
        paginator.value = resp.data || null;
      }

      console.log(resp.data);
    }

    function onUpdateOrderBY(o) {
      console.log(o);

      orderBy.value = o;
      loadGames();
    }

    async function search(f) {
      filters.value = { ...f };
      loadGames();
    }

    return {
      headers,
      page,
      paginator,
      perPage,
      orderBy,
      games,
      menu,
      showUserFiltersModal,
      onUpdateOrderBY,
      search,
    };
  },
};
</script>
