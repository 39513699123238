<template lang="">
  <div class="px-3 py-3" style="">
    <header class="d-flex align-center justify-space-between mb-3">
      <h1>Jogos ({{ !paginator ? 0 : paginator.totalElements }})</h1>
      <v-btn color="primary" link :to="{ name: 'users' }">Voltar</v-btn>
    </header>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="gamesFormated"
          :items-per-page="perPage"
          :return-object="true"
          @update:itemsPerPage="(p) => (perPage = p)"
          :page="page"
          items-per-page-text="Itens por página"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.masterRoomGame.pin }}</td>
              <td>{{ item.masterRoomGame.name }}</td>
              <td>{{ item.dateCreate }}</td>
            </tr>
          </template>

          <template v-slot:bottom v-if="paginator">
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="paginator.totalPages"
              ></v-pagination>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";

import { config } from "../../../config/AppConfig";
import { formatDate } from "date-fns";
import masterRoomGamePlayService from "../../../services/masterRoomGamePlayService";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "QuestionChatInteractionListView",
  setup() {
    const headers = ref([
      {
        title: "Pin",
        align: "start",
        key: "pin",
        sortable: false,
      },
      {
        title: "Nome",
        align: "start",
        key: "name",
        sortable: false,
      },
      {
        title: "Data Entrada",
        align: "start",
        key: "dataUltimoRegistro",
        sortable: false,
      },
    ]);
    const games = ref([]);
    const page = ref(1);
    const perPage = ref(config.registersPerPage);
    const orderBy = ref(null);
    const filters = ref({});
    const paginator = ref(null);

    /** hooks */

    const route = useRoute();
    const router = useRouter();

    watch(page, () => {
      loadGames();
    });

    watch(orderBy, () => {
      loadGames();
    });

    onMounted(() => {
      if (!route.params.userId) {
        router.push({ name: "users" });
      }
      filters.value = {
        ...filters.value,
        userId: route.params.userId,
      };
      loadGames();
    });

    const gamesFormated = computed(() => {
      return games.value.map((i) => {
        const strDate = i.dateCreate.replace("[GMT-03:00]", "");
        const dateCreate = formatDate(new Date(strDate), "dd/MM/yyyy H:m:s");
        i.dateCreate = dateCreate;
        return {
          ...i,
        };
      });
    });

    async function loadGames() {
      console.log(filters);

      const pageNumber = page.value && page.value >= 1 ? page.value - 1 : 0;
      const resp = await masterRoomGamePlayService.list(
        pageNumber,
        perPage.value,
        orderBy.value,
        filters.value
      );

      if (resp.data) {
        games.value = [...resp.data.content];
        paginator.value = resp.data || null;
      }

      console.log(resp.data);
    }

    return {
      headers,
      page,
      paginator,
      perPage,
      orderBy,
      gamesFormated,
    };
  },
};
</script>
