import http from "../infra/config/httpClient";

const list = (page, size) => {
  return http.get("/api/v2/questions-chat-interaction/group-by-user", {
    params: {
      page,
      size,
    },
  });
};

export default {
  list,
};
