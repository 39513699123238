<template lang="">
  <div class="px-3 py-3" style="">
    <header class="d-flex align-center justify-space-between mb-3">
      <h1>
        Usuários que usaram o chat de questões com IA ({{
          !paginator ? 0 : paginator.totalElements
        }})
      </h1>
    </header>
    <v-card>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="interactionsFormated"
          :items-per-page="perPage"
          :return-object="true"
          @update:itemsPerPage="(p) => (perPage = p)"
          :page="page"
          items-per-page-text="Itens por página"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.userName }}</td>
              <td>{{ item.userEmail }}</td>
              <td>
                {{ item.dataUltimoRegistro }}
              </td>
              <td>{{ item.interactions }}</td>
            </tr>
          </template>

          <template v-slot:bottom v-if="paginator">
            <div class="text-center pt-2">
              <v-pagination
                v-model="page"
                :length="paginator.totalPages"
              ></v-pagination>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { computed, onMounted, ref, watch } from "vue";

import questionChatInteractionService from "../../services/questionChatInteractionService";
import { config } from "../../config/AppConfig";
import { formatDate } from "date-fns";

export default {
  name: "QuestionChatInteractionListView",
  setup() {
    const headers = ref([
      {
        title: "Nome",
        align: "start",
        key: "userName",
        sortable: false,
      },
      {
        title: "E-mail",
        align: "start",
        key: "userEmail",
        sortable: false,
      },
      {
        title: "Último Registro",
        align: "start",
        key: "dataUltimoRegistro",
        sortable: false,
      },
      {
        title: "Quantidade",
        align: "start",
        key: "interactions",
        sortable: false,
      },
    ]);
    const interactions = ref([]);
    const page = ref(1);
    const perPage = ref(config.registersPerPage);
    const orderBy = ref("name");
    const paginator = ref(null);

    watch(page, () => {
      loadInteractions();
    });

    watch(orderBy, () => {
      loadInteractions();
    });

    onMounted(() => {
      loadInteractions();
    });

    const interactionsFormated = computed(() => {
      return interactions.value.map((i) => {
        const strDate = i.dataUltimoRegistro.replace("[GMT-03:00]", "");
        const dateCreate = formatDate(new Date(strDate), "dd/MM/yyyy H:m:s");
        i.dataUltimoRegistro = dateCreate;
        return {
          ...i,
        };
      });
    });

    async function loadInteractions() {
      console.log(orderBy);

      const pageNumber = page.value && page.value >= 1 ? page.value - 1 : 0;
      const resp = await questionChatInteractionService.list(
        pageNumber,
        perPage.value
      );

      if (resp.data) {
        interactions.value = [...resp.data.content];
        paginator.value = resp.data || null;
      }

      console.log(resp.data);
    }

    return {
      headers,
      page,
      paginator,
      perPage,
      orderBy,
      interactionsFormated,
    };
  },
};
</script>
